import React from 'react'

const ReadBeforeStart = () => {
  return (
    <div >
      <p >В соответствии с пп. 1 п. 4 Правил проведения аттестации работников АО "Жилстройсбербанк Казахстана",
        утвержденных решением Правления (протокол заседания №16 от 19.04.2018 года), <strong >аттестация</strong > –
        комплекс мероприятий,
        направленных на формирование вывода об уровне соответствия работников занимаемым должностям.</p >

      <p >
        Одним из этапов аттестации является <strong >тестирование для определения профессиональных знаний или навыков и
        знания внутренних документов Банка. </strong >
      </p >

      <p >
        Язык тестирования - русский.
      </p >

      <p >
        Продолжительность тестирования - 60 минут.
      </p >

      <p >
        Общее количество тестовых вопросов - 50, в т. ч. 25 - профессиональные, 25 - общего характера (т.е. требования и
        нормы внутренних документов Банка, которые должен знать каждый работник Банка независимо от должности и
        направления деятельности).
      </p >


      <p >Тестирование считается успешно пройденным при получении суммарного итогового балла <strong > не менее 65% -
        для
        специалистов, и не менее 75% - для уровней линейного руководителя, руководителя среднего звена и
        выше. </strong >
      </p >

      <p > На прохождение теста дается <strong >1 попытка </strong >(в тесте установлен таймер времени).</p >

      <strong >Удачного прохождения теста!</strong >
    </div >
  )
}

export default ReadBeforeStart
